import { Injectable } from '@angular/core';

import { RegisterConfirmation } from '../../models/register-confirmation';

import { RegisterConfirmationDto } from './dto/register-confirmation.dto';
import { IMapperToDto } from './mappers';

/** Mapper for register confirmation. */
@Injectable({ providedIn: 'root' })
export class RegisterConfirmationMapper
implements IMapperToDto<RegisterConfirmationDto, RegisterConfirmation> {
  /** @inheritdoc */
  public toDto(
    data: RegisterConfirmation,
  ): RegisterConfirmationDto {
    return {
      userEmail: data.email,
      code: data.key,
    };
  }
}
