import { Injectable } from '@angular/core';

import { NewEmailConfirmation } from '../../models/new-email-confirmation';

import { IMapperToDto } from './mappers';
import { NewEmailConfirmationDto } from './dto/new-email-confirmation.dto';

/** Mapper for new email confirmation. */
@Injectable({ providedIn: 'root' })
export class NewEmailConfirmationMapper
implements IMapperToDto<NewEmailConfirmationDto, NewEmailConfirmation> {
  /** @inheritdoc */
  public toDto(
    data: NewEmailConfirmation,
  ): NewEmailConfirmationDto {
    return {
      UserId: data.userId,
      Email: data.email,
      Token: data.key,
    };
  }
}
