import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { CompleteInvitation } from '../../models/complete-invitation';

import { CompleteInvitationDto } from './dto/complete-invitation.dto';
import { AppValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Mapper for complete user register after invitation. */
@Injectable({ providedIn: 'root' })
export class CompleteInvitationMapper
implements
  IMapperToDto<CompleteInvitationDto, CompleteInvitation>,
  IValidationErrorMapper<CompleteInvitationDto, CompleteInvitation> {

  /** @inheritdoc */
  public toDto(
    data: CompleteInvitation,
  ): CompleteInvitationDto {
    return {
      email: data.email,
      password: data.passwordConfirmation,
      code: data.key,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: AppValidationErrorDto<CompleteInvitationDto>,
  ): EntityValidationErrors<CompleteInvitation> {
    return {
      passwordConfirmation:
        extractErrorMessage(errorDto.password) ??
        extractErrorMessage(errorDto.email) ??
        extractErrorMessage(errorDto.code) ??
        extractErrorMessage(errorDto.non_field_errors),
    };
  }
}
